body,
html {
    height: 100%;
    margin: 0;
    font-family: "Arial", sans-serif;
}

.hero {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    background: linear-gradient(45deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
    animation: gradient 360s ease infinite;
}

.hero h1 {
    font-size: 4rem;
    color: #fff;
}

.hero p {
    font-size: 1.2rem;
    color: #fff;
}

.social-links {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.social-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    margin: 0 15px;
}

.social-links a:hover {
    color: #000;
}


/* Background gradient animation */
@keyframes gradient {
    0% {
        background: linear-gradient(45deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
    }
    25% {
        background: linear-gradient(45deg, rgba(116, 235, 213, 1) 0%, rgba(172, 182, 229, 1) 100%);
    }
    50% {
        background: linear-gradient(45deg, rgba(35, 203, 167, 1) 0%, rgba(50, 70, 87, 1) 100%);
    }
    75% {
        background: linear-gradient(45deg, rgba(20, 30, 48, 1) 0%, rgba(36, 59, 85, 1) 100%);
    }
    100% {
        background: linear-gradient(45deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
    }
}
